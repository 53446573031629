import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import logo from './assets/img/logo-dark.png';

const loginCardCenter = {
  marginTop: '10px',
  width: '400px',
  top: '30%',
  overflow: 'auto',
  left: '50%',
  position: 'fixed',
  transform: 'translate(-50%, -30%)',
  textAlign: 'center'
};

class Logout extends Component {
  render() {
    const {login, logoutUser} = this.props;
    logoutUser();

    return (
      <Card style={loginCardCenter}>
        <CardContent>
          <img src={logo} style={{width: 300, marginBottom: 30}} alt='Apprentice Health'/>
          <div><h4>Successfully Logged Out</h4></div>
          <Button onClick={login}> Log Back In </Button>
        </CardContent>
      </Card>
    );
  }
}

Logout.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired
};

export default Logout;
