import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import logo from './assets/img/logo-dark.png';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  loginCardCenter: {
    marginTop: '10px',
    width: '400px',
    top: '30%',
    overflow: 'auto',
    left: '50%',
    position: 'fixed',
    transform: 'translate(-50%, -30%)',
    textAlign: 'center'
  }
};

class LoginOut extends Component {
  login = () => {
    this.props.auth.login();
  };

  logout = () => {
    this.props.auth.logout();
    this.props.history.replace('/logout');
  };

  show = () => {
    if (this.props.auth && this.props.auth.isAuthenticated()) {
      return <Button onClick={this.logout}>{'Log Out'}</Button>;
    } else {
      return <Button onClick={this.login}>{'Log In'}</Button>;
    }
  };

  render() {
    const {classes} = this.props;
    return (
      <div>
        <div>
          <Card className={classes.loginCardCenter}>
            <CardContent>
              <div>
                <img src={logo} style={{width: 300, marginBottom: 50}} alt={'Apprentice Health'}/>
                <div><h4>Welcome!</h4></div>
                <div>{this.show()}</div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

LoginOut.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(LoginOut);
