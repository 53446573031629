import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import logo from './assets/img/logo-dark.png';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  loginCardCenter: {
    marginTop: '10px',
    width: '400px',
    top: '30%',
    overflow: 'auto',
    left: '50%',
    position: 'fixed',
    transform: 'translate(-50%, -30%)',
    textAlign: 'center'
  }
};

function DeviceActivate(props){
    const [activateUrl, setActivateUrl] = useState(""),
      [userCode, setUserCode] = useState("");

    const getDeviceCode = async () => {
      await props.auth.retrieveDeviceCode()
      setActivateUrl(props.auth.getActivateUrl())
      setUserCode(props.auth.getUserCode())
    }

    useEffect (() => {
      if (!activateUrl){
        getDeviceCode()
      }
    })

    useEffect(() => {
      const pollToken = setInterval( () => {
        if (activateUrl && userCode){
          props.auth.checkToken()
        }
      }, props.auth.getPollInterval());
      return () => clearInterval(pollToken);
    }, [activateUrl, userCode])

    return (
        <div>
          <div>
            <Card className={props.classes.loginCardCenter}>
              <CardContent>
                <div>
                  <img src={logo} style={{width: 300, marginBottom: 50}} alt={'Apprentice Health'}/>
                  <div><h4>Device Activation</h4></div>
                  <div>Navigate to <a href={activateUrl}>{activateUrl}</a> and enter in <h2>{userCode}</h2></div>
                  <div>(You can use another device to do this)</div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      );

    }

DeviceActivate.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(DeviceActivate);
