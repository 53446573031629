import React, {useContext, useEffect, useState} from 'react';
import AuthClient from './AuthClient';
import AuthDeviceClient from './AuthDeviceClient';

const {fetch, alert} = window;

export const UserContext = React.createContext(null);

export const CurrentClinicContext = React.createContext(null);
export const CurrentRealmContext = React.createContext(null);

export const AuthContext = React.createContext(null);

let auth = null;

export const useAuth = () => {
  return useContext(AuthContext);
};

export function getAuth(history, env, deviceAuth) {
  if (!history || !env) {
    throw new Error('Auth requires a history and env object.');
  }
  if (!auth) {
    if (deviceAuth){
      auth = new AuthDeviceClient({history: history, env: env});
    } else {
      auth = new AuthClient({history: history, env: env});
    }
  }
  return auth;
}

const USER_NOT_EXISTS_REGEX = /User .* not recognized/;

function getIdentityUrl(baseUrl) {
  return baseUrl.endsWith('/')
    ? `${baseUrl}useradmin/users/identity`
    : `${baseUrl}/useradmin/users/identity`;
}

function useUser(auth, env, history, baseUrl = process.env.REACT_APP_BACKEND_USERADMIN_URL) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const nonexistent_user = 'This user is not authorized to access the Apprentice Health Application. Please contact your Apprentice Health administrator.'
  useEffect(() => {
    let getUser = async () => {
      try {
        let response = await fetch(getIdentityUrl(baseUrl), {
          method: 'GET',
          headers: auth.getHeaders()
        });
        if (response.status === 401) {
          const contentType = response.headers.get('content-type');
          if (!contentType || (!contentType.includes('application/json') && !contentType.includes('text/html'))) {
            auth.logout();
            history.replace('/logout');
          } else if (contentType.includes('text/html')) {
            let error = await response.text();
            if (USER_NOT_EXISTS_REGEX.test(error)) {
              alert(nonexistent_user);
              auth.logout();
              history.replace('/logout');
            } else {
              auth.logout();
              history.replace('/logout');
            }
          } else if (contentType.includes('application/json')) {
            let error = await response.json();
            if (error.code === 'USER_NOT_EXIST') {
              alert(nonexistent_user);
              auth.logout();
              history.replace('/logout');
            } else {
              auth.logout();
              history.replace('/logout');
            }
          }
        } else {
          setUser(await response.json());
          setLoading(false);
        }
      } catch (error) {
        console.error('userAuthService.getUser', error);
      }
    };
    getUser();
  }, []);
  return [user, loading];
}

export default useUser;
