import makeStyles from '@material-ui/core/styles/makeStyles';

export const pickerStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    color: 'white'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  menuItem: {
    display: null
  }
}));
