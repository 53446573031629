import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {CurrentClinicContext, UserContext} from './UserAuthService';
import {pickerStyles} from './styles/ahAuthStyles';




function getMuiTheme(args=[]) {
  return createMuiTheme({
      overrides: {
        MuiSelect: {
          select : {
            color: 'white'
          },
          icon: {
            color: "white"
          }
        },
        MuiInput:{
          underline: {
            '&::before': {
              borderBottom: "0px"
            },
            '&::after': {
              borderBottom: "0px"
            }
          },
          input: {
            margin: 'inherit'
          }
        }
      }
  }, args)
}

/**
 * @return {null}
 */
function ClinicPicker({children, muiTheme=null}) {
  const classes = pickerStyles();
  const user = useContext(UserContext);
  const {currentClinic, setCurrentClinic} = useContext(CurrentClinicContext);
  const handleChange = (event) => {
    setCurrentClinic(event.target.value);
    localStorage.setItem('cid', event.target.value);
  };
  if (!currentClinic) {
    return null;
  }
  if (user.clinicRoles.length === 1) {
    setCurrentClinic(user.clinicRoles[0].site_id);
  }

  return (
    user.clinicRoles.length <= 1 ? 
      <React.Fragment>{children}</React.Fragment>
    : 
      <div style={{display: 'flex', flexDirection: 'row', justifyContext: 'center'}}>
        <MuiThemeProvider theme={getMuiTheme(muiTheme ? muiTheme : [])}>
        <form autoComplete='off'>
          <FormControl className={classes.formControl}>
            <Select
              value={currentClinic}
              onChange={handleChange}
              inputProps={{
                id: 'clinic-helper'
              }}
            >
              {user.clinicRoles.map(cr => {
                return <MenuItem style={{display: 'flex', padding: '4px 16px 4px 16px', textAlign: 'left'}}
                  key={cr.site_id}
                  value={cr.site_id}>{cr.clinic.site_display_name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </form>
        </MuiThemeProvider>
        {children}
      </div>
  );
}

ClinicPicker.propTypes = {
  children: PropTypes.object
};

export default ClinicPicker;
