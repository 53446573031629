export const ROLES = {
    SUPER_USER: -100,
    CUSTOMER_ADMIN: -1,
    CLINIC_ADMIN: 200,
    STAFF: 300,
    BADGE_SCAN: 400,
    WORKFLOW_ONLY: 500,
    PATIENT_WORKFLOW: 600,
    REPORTING_USER: 1000,
    IT_STAFF: 1100,
    NO_PERMISSION: 9999999
};