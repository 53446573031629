import PropTypes from 'prop-types';

function RequireAuth(props) {
  if (!props.auth.isAuthenticated()) {
    props.history.push('/login');
  }

  return props.children;
}

RequireAuth.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.object
};

export default RequireAuth;
