import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {CurrentRealmContext, UserContext} from './UserAuthService';
import {pickerStyles} from './styles/ahAuthStyles';
import {humanize} from './utils/Utils';

/**
 * @return {null}
 */
function RealmPicker({children, showUnassigned=false}) {
  const classes = pickerStyles();
  const user = useContext(UserContext);

  const {currentRealm, setCurrentRealm} = useContext(CurrentRealmContext);

  const handleChange = (event) => {
    setCurrentRealm(event.target.value);
    localStorage.setItem('rid', event.target.value);
  };

  return (user.realms.length < 1 ? <React.Fragment>{children}</React.Fragment>
    : <div style={{display: 'flex', flexDirection: 'row', justifyContext: 'center'}}>
      <form className={classes.root} autoComplete='off'>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor='clinic-helper' style={{color: 'white'}}>Realm</InputLabel>
          <Select
            value={currentRealm}
            onChange={handleChange}
            style={{margin: 0, color: 'white'}}
            inputProps={{
              name: 'realm',
              id: 'realm-helper'
            }}
          >
            {user.realms.map(cr => {
              return <MenuItem style={{display: 'flex', padding: '4px 16px 4px 16px', textAlign: 'left'}}
                key={cr.realm_id}
                value={cr.realm_id}>{humanize(cr.realm_name)}</MenuItem>;
            })}
            {/*fixme this is terrible, but i can't get it to use a single ternary select w/o also breaking the way to get the value of selected component*/}
            {showUnassigned ? <ListSubheader key='divider'><Divider/></ListSubheader>:null}
            {showUnassigned ?
               <MenuItem style={{display: 'flex', padding: '4px 16px 4px 16px', textAlign: 'left'}}
                        key='unassigned'
                        value='0'>No Realm</MenuItem>:null
            }
          </Select>
        </FormControl>
      </form>
      {children}
    </div>
  );
}

RealmPicker.propTypes = {
  children: PropTypes.object,
  showUnassigned: PropTypes.bool
};

export default RealmPicker;
