import React, {useEffect, useState} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import RequireAuth from './RequireAuth';
import Callback from './Callback';
import Logout from './Logout';
import LoginOut from './LoginOut';
import useUser, {getAuth, AuthContext, UserContext, CurrentClinicContext, CurrentRealmContext} from './UserAuthService';
import DeviceActivate from './DeviceActivate';

/**
 * @return {null}
 */
function Wrapper(props) {
  const [user] = useUser(props.auth, props.env, props.history);
  const [currentClinic, setCurrentClinic] = useState(null);
  const [currentRealm, setCurrentRealm] = useState(null);

  useEffect(() => {
    if (user) {
      const cid = localStorage.getItem('cid') ? parseInt(localStorage.getItem('cid')) : null;
      const rid = localStorage.getItem('rid') ? parseInt(localStorage.getItem('rid')) : null; //fixme -- unassigned is stored as integer 0.

      if (user.clinicRoles.map(cr => cr.site_id).includes(cid)) {
        setCurrentClinic(cid);
      } else if (!user.defaultClinicId) {
        setCurrentClinic(user.clinicRoles.map(cr => cr.site_id)[0]);
      } else {
        setCurrentClinic(user.defaultClinicId);
      }

      if (user.realms.map(cr => cr.realm_id).includes(rid)) {
        setCurrentRealm(rid);
      } else if (rid === 0) {
        setCurrentRealm(0); // unassigned!!
      } else {
        setCurrentRealm(user.realms.map(cr => cr.realm_id)[0]);
      }

    }


  }, [user]);

  if (!user || currentClinic === null || currentRealm === null) {
    return null;
  }

  return (<AuthContext.Provider value={props.auth}>
    <UserContext.Provider value={user}>
      <CurrentRealmContext.Provider value={{currentRealm, setCurrentRealm}}>
      <CurrentClinicContext.Provider value={{currentClinic, setCurrentClinic}}>
        <React.Fragment>{props.children(currentRealm, currentClinic, user)}</React.Fragment>
      </CurrentClinicContext.Provider>
      </CurrentRealmContext.Provider>
    </UserContext.Provider>
  </AuthContext.Provider>);
}

Wrapper.propTypes = {
  children: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  env: PropTypes.object.isRequired
};

function AuthRouting(props) {
  const {routes, history, env, deviceAuth} = props;
  const [auth, setAuth] = useState(getAuth(history, env, deviceAuth));
  const DefaultComponent = props.defaultComponent;
  return <Router history={history}>
    <div className='container'>
      <div>
        <Switch>
          {deviceAuth ?
            <Route path='/login' render={() => <DeviceActivate auth={auth} history={history}/>}/> 
            :
            <Route path='/login' render={() => <LoginOut auth={auth} history={history}/>}/> 
          }

          {deviceAuth ? 
            <Route path='/logout' render={() => {auth.logout();history.replace('/login')}}/>
            :
            <Route path='/logout' render={() => <Logout logoutUser={auth.logout} login={auth.login}/>}/>
          }
          
          
          <Route path='/callback' render={() => {
            auth.handleAuthentication()
            return <Callback/>;
          }}/>
          {routes.map(r => {
            const Component = r.component;
            return <Route path={r.path} render={() => {
              return <RequireAuth auth={auth} history={history}>
                <Wrapper auth={auth} history={history} env={env}>
                  {(currentRealm, currentClinic, user) => {
                    return <Component auth={auth} env={env} history={history} user={user}
                                      currentRealm={currentRealm}
                                      currentClinic={currentClinic}/>;
                  }}
                </Wrapper>
              </RequireAuth>;
            }}/>;
          })}
          <Route path='/' render={() => {
            return <RequireAuth auth={auth} history={history}>
              <Wrapper auth={auth} history={history} env={env}>
                {(currentRealm, currentClinic, user) => {
                  return <DefaultComponent auth={auth} env={env} history={history} user={user}
                                           currentClinic={currentClinic}
                                           currentRealm={currentRealm}/>;
                }}
              </Wrapper>
            </RequireAuth>;
          }}/>
        </Switch>
      </div>
    </div>
  </Router>;
}

AuthRouting.propTypes = {
  routes: PropTypes.array.isRequired,
  defaultComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element
  ]).isRequired,
  history: PropTypes.object.isRequired,
  env: PropTypes.object.isRequired
};

export default AuthRouting;
