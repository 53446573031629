import React, {useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios'
import qs from 'query-string';

const randomString = function (length) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

class AuthClient extends React.Component {
  authToken;

  constructor(props) {
    super(props);

    this.params = props && props.params ? props.params : this.getParams(props.env);
    this.storedAuth = window.localStorage.getItem('auth') ? JSON.parse(window.localStorage.getItem('auth')) : null;

    this._deviceCode = ""
    this.userCode = ""
    this.activateUrl = ""
    this._poll_interval = -1
  }

  getActivateUrl = () => {
    return this.activateUrl
  }

  getUserCode = () => {
    return this.userCode;
  }

  getPollInterval = () => {
    return this._poll_interval;
  }

  retrieveDeviceCode = async () => {
    const url = 'https://' + this.params['domain'] + '/oauth/device/code/';
    const form = {client_id: this.params['clientID'], scope: this.params['scope'], audience: this.params['audience']};
    const options = {headers: {'content-type': 'application/x-www-form-urlencoded' }}

    let response = await axios.post(url,qs.stringify(form), options)

    this.activateUrl = response['data']['verification_uri'];
    this.userCode = response['data']['user_code']
    this._poll_interval = response['data']['interval'] * 1000
    this._deviceCode = response['data']['device_code']
  }

  checkToken = async () => {
    const url = 'https://' + this.params['domain'] + '/oauth/token/';
    const form = {
      grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
      device_code: this._deviceCode,
      client_id: this.params['clientID']
    }
    const options = {headers: {'content-type': 'application/x-www-form-urlencoded' }}

    let response = await axios.post(url,qs.stringify(form), options);

    if (response){
      this.setAuthToken(response['data'])
      this.props.history.replace('/callback');
    }
  }

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      if (this.isAuthenticated()) {
        this.setSession(this.getAuthToken());
        resolve(this);
      }
    });
  };

  setSession(authResult) {
    // Set the time that the access token will expire at

    const savedUrl = window.localStorage.getItem(authResult.state);
    if (savedUrl) {

      this.props.history.replace(savedUrl);
    } else {
      this.props.history.replace('/');
    }
    window.localStorage.setItem('auth', JSON.stringify(this.getAuthToken()));
  }

  logout = () => {
    this.setAuthToken(null);
    window.localStorage.removeItem('auth');
  };

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    if (this.getAuthToken()) {

      let expiresAt = this.getAuthToken().expires_at;
      return new Date().getTime() < expiresAt;
    }
    return false;
  };

  getHeaders = () => {
    return {
      'Authorization': `Bearer ${this.getAuthToken().access_token}`,
      'X-ID-Token': this.getAuthToken().id_token
    };
  };

  getEnv = (env, key) => {
    let myEnv = env || process.env;
    return myEnv[key] ? myEnv[key] : myEnv['STORYBOOK_' + key];
  };

  getParams = (env) => {
    let nonce = randomString(50);

    if (window.location.pathname !== '/callback'
        && window.location.pathname !== '/login'
        && window.location.pathname !== '/logout') {
      window.localStorage.setItem(nonce, window.location.pathname);
    }
    return {
      domain: this.getEnv(env, 'REACT_APP_AUTH0_DOMAIN'),
      clientID: this.getEnv(env, 'REACT_APP_AUTH0_CLIENT_ID'),
      redirectUri: window.location.origin + '/callback',
      audience: this.getEnv(env, 'REACT_APP_AUTH0_AUDIENCE'),
      responseType: 'token id_token',
      scope: 'openid profile email read:events read:messages',
      state: nonce
    };
  };

  getAuthToken = () => {
    if (this.authToken && this.authToken.access_token && this.authToken.id_token) {
      return this.authToken;
    } else if (this.storedAuth && this.storedAuth.access_token && this.storedAuth.id_token) {
      this.authToken = this.storedAuth;
      return this.authToken;
    }
    return null;
  };

  getAccessToken = () => {
    return this.getAuthToken() === null ? null : this.getAuthToken().access_token;
  };

  setAuthToken = (authResult) => {
    let authToken = null;
    if (authResult){
      let expiresAt = authResult.expires_at ? authResult.expires_at : JSON.stringify((authResult.expires_in * 1000) + new Date().getTime());
      authToken = {
        access_token: authResult.access_token,
        id_token: authResult.id_token,
        expires_at: expiresAt
      };
    }

    this.authToken = authToken;
    window.localStorage.setItem('auth', JSON.stringify(authToken));
  };
}

AuthClient.propTypes = {
  history: PropTypes.object,
  params: PropTypes.object,
  env: PropTypes.object
};

export default AuthClient;
