import { Auth0Client } from '@auth0/auth0-spa-js';
import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

const randomString = function (length) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

class AuthClient extends React.Component {
  authToken;

  constructor(props) {
    super(props);
    this.params = this.getParams(props);
    this.storedAuth = window.localStorage.getItem('auth') ? JSON.parse(window.localStorage.getItem('auth')) : null;

    this.auth = new Auth0Client({
      domain: this.params['domain'],
      client_id: this.params['client_id'],
      redirect_uri : this.params['redirectUri'],
      audience: this.params['audience'],
      useRefreshTokens : true,
      cacheLocation: 'localstorage'
    });

    this.cookies = new Cookies();
  }

  login = () => {
    this.auth.loginWithRedirect()
  };

  handleAuthentication = async () => {

    this.auth.handleRedirectCallback().then(redirectResult => {
      //logged in. you can get the user profile like this:

      this.setSession().then(value => {
        this.props.history.replace('/');
      })
    });
  };

  async setSession() {
    const user = await this.auth.getIdTokenClaims()
    if (!user){
      return
    }

    const previousSave = this.getAuthToken();
    let last_retrieval = null;
    let tokenOptions = {}
    if (previousSave && previousSave.last_retrieval && previousSave.last_retrieval >= (new Date()).getTime()){
      last_retrieval = previousSave.last_retrieval
    }else{
      last_retrieval = (new Date()).getTime() + (60 * 60 * 1000)
      tokenOptions = {ignoreCache: true}
    }

    this.setAuthToken({
      access_token: await this.auth.getTokenSilently(tokenOptions),
      id_token: user.__raw,
      last_retrieval: last_retrieval
    });
  }

  logout = () => {
    this.setAuthToken(null);
    window.localStorage.removeItem('auth');
    this.storedAuth = null;
    
    this.auth.logout({returnTo: window.location.origin})
  };

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    if (this.getAuthToken()) {
      return true;
    }
    return false;
  };

  getHeaders = (disableTokenRefresh=false) => {
    const tokens = this.getAuthToken();

    if (!disableTokenRefresh){
      this.setSession()
    }

    return {
      'Authorization': `Bearer ${tokens.access_token}`,
      'X-ID-Token': tokens.id_token
    };
  };

  getEnv = (env, key) => {
    let myEnv = env || process.env;
    return myEnv[key] ? myEnv[key] : myEnv['STORYBOOK_' + key];
  };

  getParams = (props) => {
    if (props && props.params){
      return props.params
    } 

    const env = props.env


    let nonce = randomString(50);
    if (window.location.pathname !== '/callback'
        && window.location.pathname !== '/login'
        && window.location.pathname !== '/logout') {
      window.localStorage.setItem(nonce, window.location.pathname);
    }
    return {
      domain: this.getEnv(env, 'REACT_APP_AUTH0_DOMAIN'),
      client_id: this.getEnv(env, 'REACT_APP_AUTH0_CLIENT_ID'),
      redirectUri: window.location.origin + '/callback',
      audience: this.getEnv(env, 'REACT_APP_AUTH0_AUDIENCE'),
      state: nonce
    };
  };

  getAuthToken = () => {
    if (this.authToken && this.authToken.access_token && this.authToken.id_token) {
      return this.authToken;
    } else if (this.storedAuth && this.storedAuth.access_token && this.storedAuth.id_token) {
      this.authToken = this.storedAuth;
      return this.authToken;
    }
    return null;
  };

  getAccessToken = () => {
    return this.getAuthToken() === null ? null : this.getAuthToken().access_token;
  };

  setAuthToken = (authToken) => {
    this.authToken = authToken;
    window.localStorage.setItem('auth', JSON.stringify(authToken));

    if (authToken){
      this.cookies.set('authHeaders', JSON.stringify(this.getHeaders(true)), { path: '/documentation' });
    }

  };
}

AuthClient.propTypes = {
  history: PropTypes.object,
  params: PropTypes.object,
  env: PropTypes.object
};

export default AuthClient;
